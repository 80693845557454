// export const siteConfig = {
//     baseUrl: process.env.NODE_ENV !== "production" ? window.location.protocol + "//192.168.0.18:5002/v1" : window.location.protocol + "//" + window.location.host + "/v1",
//     ptzControlUrl: window.location.protocol + "//os12.san02.camzone.com/ctrl",
//     jsPullUrl: window.location.protocol + "//os12.san02.camzone.com/frame",
// };
export const siteConfig = {
    baseUrl: process.env.NODE_ENV !== "production" ? window.location.protocol + "//192.168.0.18:5002/v1" : "/v1",
    ptzControlUrl: "/os12/ctrl",
    jsPullUrl: "/os12/frame",
};
